import { DOCUMENT, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, ErrorHandler, NgModule } from '@angular/core';
import { Subject } from 'rxjs';
import { throttleTime, groupBy, mergeMap } from 'rxjs/operators';
import { BrowserMicroSentryClient } from '@micro-sentry/browser';
const MICRO_SENTRY_ERRORS_THROTTLE_TIME = 1000;
const MICRO_SENTRY_ERRORS_THROTTLE = new InjectionToken('Micro sentry error throttle', {
  providedIn: 'root',
  factory() {
    return source => source.pipe(throttleTime(MICRO_SENTRY_ERRORS_THROTTLE_TIME));
  }
});
class MicroSentryErrorBusService {
  constructor(throttle) {
    this.throttle = throttle;
    this._errors$ = new Subject();
    this.errors$ = this._errors$.pipe(groupBy(error => error?.toString()), mergeMap(group => group.pipe(this.throttle)));
  }
  ngOnDestroy() {
    this._errors$.complete();
  }
  next(error) {
    this._errors$.next(error);
  }
  static {
    this.ɵfac = function MicroSentryErrorBusService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MicroSentryErrorBusService)(i0.ɵɵinject(MICRO_SENTRY_ERRORS_THROTTLE));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MicroSentryErrorBusService,
      factory: MicroSentryErrorBusService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MicroSentryErrorBusService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [MICRO_SENTRY_ERRORS_THROTTLE]
    }]
  }], null);
})();
const MICRO_SENTRY_CONFIG = new InjectionToken('Micro Sentry Options');
class MicroSentryService extends BrowserMicroSentryClient {
  constructor(config, document) {
    super(config, document?.defaultView ?? undefined);
  }
  getRequestBlank() {
    return {
      ...super.getRequestBlank(),
      sdk: {
        name: 'micro-sentry.javascript.angular',
        version: '0.0.0'
      }
    };
  }
  ngOnDestroy() {
    this.destroy();
  }
  static {
    this.ɵfac = function MicroSentryService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MicroSentryService)(i0.ɵɵinject(MICRO_SENTRY_CONFIG), i0.ɵɵinject(DOCUMENT));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MicroSentryService,
      factory: MicroSentryService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MicroSentryService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [MICRO_SENTRY_CONFIG]
    }]
  }, {
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }], null);
})();
class MicroSentryErrorHandler {
  constructor(errorBus, microSentry) {
    this.errorBus = errorBus;
    errorBus.errors$.subscribe(error => {
      microSentry.report(error);
    });
  }
  handleError(error) {
    this.errorBus.next(error);
    console.error(error);
  }
  static {
    this.ɵfac = function MicroSentryErrorHandler_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MicroSentryErrorHandler)(i0.ɵɵinject(MicroSentryErrorBusService), i0.ɵɵinject(MicroSentryService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MicroSentryErrorHandler,
      factory: MicroSentryErrorHandler.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MicroSentryErrorHandler, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: MicroSentryErrorBusService
  }, {
    type: MicroSentryService
  }], null);
})();
function provideMicroSentry(config) {
  return [{
    provide: ErrorHandler,
    useExisting: MicroSentryErrorHandler
  }, {
    provide: MICRO_SENTRY_CONFIG,
    useValue: config
  }];
}
class MicroSentryModule {
  static forRoot(config) {
    return {
      ngModule: MicroSentryModule,
      providers: provideMicroSentry(config)
    };
  }
  static {
    this.ɵfac = function MicroSentryModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MicroSentryModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MicroSentryModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MicroSentryModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [CommonModule]
    }]
  }], null, null);
})();

// modules

/**
 * Generated bundle index. Do not edit.
 */

export { MICRO_SENTRY_CONFIG, MICRO_SENTRY_ERRORS_THROTTLE, MICRO_SENTRY_ERRORS_THROTTLE_TIME, MicroSentryErrorBusService, MicroSentryModule, MicroSentryService, provideMicroSentry };
