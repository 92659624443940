import { Environment } from '@wheere-front/inside-shared-util';

export const environment: Environment = {
  production: false,
  type: 'develop',
  url: 'https://inside.dev.wheere.tech',
  api: {
    customer: 'https://customer.dev.wheere.tech/api',
    tracker: 'https://tracker.dev.wheere.tech/api',
    gateway: 'https://gateway.dev.wheere.tech/api',
    site: 'https://site.dev.wheere.tech/api',
  },
  keycloak: {
    issuer: 'https://gateway.dev.wheere.tech/auth',
    realm: 'wheere',
    clientId: 'frontend',
  },
  maptiles: 'D9ZvuUNhUV4ar033H5oD',
};
