import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideTransloco } from '@jsverse/transloco';
import { provideMicroSentry } from '@micro-sentry/angular';
import { provideHotToastConfig } from '@ngxpert/hot-toast';
import { provideFastSVG } from '@push-based/ngx-fast-svg';
import { INSIDE_APP_ENVIRONMENT } from '@wheere-front/inside-shared-util';
import { authInterceptor, provideKeycloak } from '@wheere-front/shared-auth';
import { provideTooltip } from '@wheere-front/shared-ui';
import { APP_ENVIRONMENT_KEYCLOAK } from '@wheere-front/shared-util';
import { BASE_PATH as OPENAPI_CUSTOMER } from '@wheere/api-customer';
import { BASE_PATH as OPENAPI_GATEWAY } from '@wheere/api-gateway';
import { BASE_PATH as OPENAPI_SITE } from '@wheere/api-site';
import { BASE_PATH as OPENAPI_TRACKER } from '@wheere/api-tracker';
import { KeycloakOptions } from 'keycloak-angular';
import { environment } from '../environments';
import { routes } from './app.routes';
import { configTransloco } from './core/transloco-config';

const keycloakOptions: KeycloakOptions = {
  config: {
    url: environment.keycloak.issuer,
    realm: environment.keycloak.realm,
    clientId: environment.keycloak.clientId,
  },
  loadUserProfileAtStartUp: true,
  initOptions: {
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
  },
};

// Provide Sentry only for non-local environments
const provideSentry = () => {
  return (
    environment.type !== 'local'
      ? [
          provideMicroSentry({
            dsn: 'https://df35a8af412c4432924e9c582668b9c3@app.glitchtip.com/8318',
            release: '0.0.0',
            environment: environment.type,
          }),
        ]
      : []
  ).flat();
};

export const libConfig: ApplicationConfig = {
  providers: [
    { provide: OPENAPI_CUSTOMER, useValue: environment.api.customer },
    { provide: OPENAPI_TRACKER, useValue: environment.api.tracker },
    { provide: OPENAPI_GATEWAY, useValue: environment.api.gateway },
    { provide: OPENAPI_SITE, useValue: environment.api.site },
    { provide: INSIDE_APP_ENVIRONMENT, useValue: environment },
    { provide: APP_ENVIRONMENT_KEYCLOAK, useValue: environment.keycloak },
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideHttpClient(withInterceptors([authInterceptor])),
    provideRouter(routes, withComponentInputBinding()),
    provideAnimationsAsync(),
    provideKeycloak(keycloakOptions),
    provideTransloco(configTransloco),
    provideTooltip(),
    provideHotToastConfig(),
    provideFastSVG({
      url: (name: string) => `assets/svg/${name}.svg`,
    }),
    provideSentry(),
  ],
};
