import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { provideIcons } from '@ng-icons/core';
import { ngIcons } from '@wheere-front/inside-shared-data-access';
import { KeycloakUserConnectService } from '@wheere-front/shared-auth';
import { PrimeNGConfig } from 'primeng/api';
import { PrimengPresetConfig } from '@wheere-front/shared-primeng-preset';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  template: ` <router-outlet />`,
  providers: [provideIcons(ngIcons)],
})
export class AppComponent {
  #keycloakUserConnectService = inject(KeycloakUserConnectService);

  constructor(private config: PrimeNGConfig) {
    this.config.theme.set({
      preset: PrimengPresetConfig,
      options: {
        darkModeSelector: '.my-app-dark',
        cssLayer: {
          name: 'primeng',
          order: 'tailwind-base, tailwind-utilities,primeng',
        },
      },
    });
    this.#keycloakUserConnectService.init();
  }
}
