var Severity;
(function (Severity) {
  Severity["fatal"] = "fatal";
  Severity["error"] = "error";
  Severity["warning"] = "warning";
  Severity["log"] = "log";
  Severity["info"] = "info";
  Severity["debug"] = "debug";
  Severity["critical"] = "critical";
})(Severity || (Severity = {}));

// @for-internal-use
// Chromium based browsers: Chrome, Brave, new Opera, new Edge
const CHROME_REGEXP = /^\s*at (?:(.*?) ?\()?((?:file|https?|blob|chrome-extension|address|native|eval|webpack|<anonymous>|[-a-z]+:|.*bundle|\/).*?)(?::(\d+))?(?::(\d+))?\)?\s*$/i;
// gecko regex: `(?:bundle|\d+\.js)`: `bundle` is for react native, `\d+\.js` also but specifically for ram bundles because it
// generates filenames without a prefix like `file://` the filenames in the stacktrace are just 42.js
// We need this specific case for now because we want no other regex to match.
const GECKO_REGEXP = /^\s*(.*?)(?:\((.*?)\))?(?:^|@)?((?:file|https?|blob|chrome|webpack|resource|moz-extension).*?:\/.*?|\[native code\]|[^@]*(?:bundle|\d+\.js))(?::(\d+))?(?::(\d+))?\s*$/i;
const WINJS_REGEXP = /^\s*at (?:((?:\[object object\])?.+) )?\(?((?:file|ms-appx|https?|webpack|blob):.*?):(\d+)(?::(\d+))?\)?\s*$/i;
const UNKNOWN_FUNCTION = '?';
const AUTH_HEADER = 'X-Sentry-Auth';
const DSN_REGEXP =
//|protocol     |public    |secret      |host    |port       |projectId
//|
/^(?:(\w+):)\/\/(?:([\w-]+)(?::(\w+))?@)([\w.-]+)(?::(\d+))?\/(.+)/;

// @for-internal-use
/* eslint-disable @typescript-eslint/no-explicit-any */
function computeStackTraceFromStackProp(ex) {
  if (!ex.stack) {
    return null;
  }
  const stack = [];
  const lines = ex.stack.split('\n');
  let parts;
  let element;
  for (let i = 0; i < lines.length; ++i) {
    // tslint:disable-next-line:no-conditional-assignment
    if (parts = CHROME_REGEXP.exec(lines[i])) {
      element = {
        // working with the regexp above is super painful. it is quite a hack, but just stripping the `address at `
        // prefix here seems like the quickest solution for now.
        filename: parts[2] && parts[2].indexOf('address at ') === 0 ? parts[2].substr('address at '.length) : parts[2],
        function: parts[1] || UNKNOWN_FUNCTION,
        lineno: parts[3] ? +parts[3] : null,
        colno: parts[4] ? +parts[4] : null
      };
      // tslint:disable-next-line:no-conditional-assignment
    } else if (parts = WINJS_REGEXP.exec(lines[i])) {
      element = {
        filename: parts[2],
        function: parts[1] || UNKNOWN_FUNCTION,
        lineno: +parts[3],
        colno: parts[4] ? +parts[4] : null
      };
      // tslint:disable-next-line:no-conditional-assignment
    } else if (parts = GECKO_REGEXP.exec(lines[i])) {
      if (i === 0 && !parts[5] && ex.columnNumber !== void 0) {
        // FireFox uses this awesome columnNumber property for its top frame
        // Also note, Firefox's column number is 0-based and everything else expects 1-based,
        // so adding 1
        // NOTE: this hack doesn't work if top-most frame is eval
        // TODO: why do not we include column into an interface?
        stack[0].column = ex.columnNumber + 1;
      }
      element = {
        filename: parts[3],
        function: parts[1] || UNKNOWN_FUNCTION,
        lineno: parts[4] ? +parts[4] : null,
        colno: parts[5] ? +parts[5] : null
      };
    } else {
      continue;
    }
    if (!element.function && element.lineno) {
      element.function = UNKNOWN_FUNCTION;
    }
    stack.push(element);
  }
  if (!stack.length) {
    return null;
  }
  return {
    value: extractMessage(ex),
    type: ex.name,
    stacktrace: {
      frames: stack.reverse()
    }
  };
}
function extractMessage(ex) {
  const message = ex && ex.message || 'No error message';
  return message.split('\n').filter(s => !!s)[0];
}
function computeStackTrace(ex) {
  // tslint:disable:no-unsafe-any
  try {
    const stack = computeStackTraceFromStackProp(ex);
    if (stack) {
      return stack;
    }
  } catch (e) {
    //
  }
  return {
    value: extractMessage(ex),
    type: ex && ex.name,
    stacktrace: {
      frames: []
    }
  };
}

/**
 * @description Hexadecimal string representing a uuid4 value.
 * The length is exactly 32 characters.
 * Dashes are not allowed.
 * Has to be lowercase.
 * @see https://develop.sentry.dev/sdk/event-payloads/#required-attributes
 */
function uuid4() {
  return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = Math.random() * 16 | 0;
    const v = c === 'x' ? r : r & 0x3 | 0x8;
    return v.toString(16);
  });
}
class MicroSentryClient {
  constructor(options) {
    if (options && options.dsn) {
      const searched = DSN_REGEXP.exec(options.dsn);
      const dsn = searched ? searched.slice(1) : [];
      const pathWithProjectId = dsn[5].split('/');
      const path = pathWithProjectId.slice(0, -1).join('/');
      this.apiUrl = dsn[0] + '://' + dsn[3] + (dsn[4] ? ':' + dsn[4] : '') + (path ? '/' + path : '') + '/api/' + pathWithProjectId.pop() + '/store/';
      this.authHeader = 'Sentry sentry_version=7,sentry_key=' + dsn[1] + (dsn[2] ? ',sentry_secret=' + dsn[2] : '');
    }
    this.environment = options && options.environment;
  }
  prepare(error) {
    return {
      ...this.getRequestBlank(),
      exception: {
        values: [computeStackTrace(error)]
      }
    };
  }
  report(error) {
    this.send(this.prepare(error));
  }
  send(body) {
    if (!this.apiUrl || !body) {
      return;
    }
    this.createRequest(body);
  }
  createRequest(body) {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', this.apiUrl, true);
    xhr.setRequestHeader('Content-type', 'application/json');
    xhr.setRequestHeader(AUTH_HEADER, this.authHeader || '');
    xhr.send(JSON.stringify(body));
  }
  getRequestBlank() {
    return {
      platform: 'javascript',
      event_id: uuid4(),
      sdk: {
        name: 'micro-sentry.javascript.core',
        version: '0.0.0'
      },
      timestamp: Date.now() / 1000,
      environment: this.environment
    };
  }
}

// models

/**
 * Generated bundle index. Do not edit.
 */

export { AUTH_HEADER, CHROME_REGEXP, DSN_REGEXP, GECKO_REGEXP, MicroSentryClient, Severity, UNKNOWN_FUNCTION, WINJS_REGEXP };
