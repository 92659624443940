import { Routes } from '@angular/router';
import {
  DEVICE_ROLES,
  GATEWAY_ROLES,
  SALE_ROLES,
  SITE_ROLES,
  USER_ROLES,
} from '@wheere-front/inside-shared-data-access';
import { authenticationGuard, userHasAnyRole } from '@wheere-front/shared-auth';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: 'home',
    loadComponent: () => import('./home.component'),
    canActivate: [authenticationGuard()],
  },
  {
    path: 'devices',
    loadChildren: () => import('@wheere-front/inside-devices').then(m => m.DEVICES_ROUTES),
    canActivate: [authenticationGuard(), userHasAnyRole(DEVICE_ROLES)],
  },
  {
    path: 'gateways',
    loadChildren: () => import('@wheere-front/inside-gateways').then(m => m.GATEWAYS_ROUTES),
    canActivate: [authenticationGuard(), userHasAnyRole(GATEWAY_ROLES)],
  },
  {
    path: 'sites',
    loadChildren: () => import('@wheere-front/inside-sites').then(m => m.SITES_ROUTES),
    canActivate: [authenticationGuard(), userHasAnyRole(SITE_ROLES)],
  },
  {
    path: 'users',
    loadChildren: () => import('@wheere-front/inside-users').then(m => m.USERS_ROUTES),
    canActivate: [authenticationGuard(), userHasAnyRole(USER_ROLES)],
  },
  {
    path: 'sales',
    loadChildren: () => import('@wheere-front/inside-sales').then(m => m.SALES_ROUTES),
    canActivate: [authenticationGuard(), userHasAnyRole(SALE_ROLES)],
  },
  {
    path: 'account',
    loadComponent: () => import('@wheere-front/inside-shared-feature').then(m => m.AccountComponent),
    canActivate: [authenticationGuard()],
  },
  {
    path: 'forbidden',
    loadComponent: () => import('@wheere-front/shared-feature').then(m => m.ForbiddenComponent),
  },
  {
    path: '**',
    loadComponent: () => import('@wheere-front/shared-feature').then(m => m.NotFoundComponent),
  },
];
