import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { provideIcons } from '@ng-icons/core';
import { ngIcons } from '@wheere-front/inside-shared-data-access';
import { KeycloakUserConnectService } from '@wheere-front/shared-auth';
import { PrimeNG } from 'primeng/config';
import { Toast } from 'primeng/toast';
import { NetworkPollingService } from '@wheere-front/shared-util';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, Toast],
  template: ` <router-outlet />
    <p-toast position="top-center" preventOpenDuplicates="true"></p-toast>`,
  providers: [provideIcons(ngIcons)],
})
export class AppComponent {
  #keycloakUserConnectService = inject(KeycloakUserConnectService);

  constructor(
    private primeNG: PrimeNG,
    private networkPolling: NetworkPollingService
  ) {
    this.#keycloakUserConnectService.init();
    this.primeNG.ripple.set(true);
  }
}
