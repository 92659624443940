import { definePreset } from '@primeng/themes';
import Aura from '@primeng/themes/aura';

export const PrimengPresetConfig = definePreset(Aura, {
  semantic: {
    primary: {
      50: '#fdf2f8',
      100: '#fce7f3',
      200: '#fbcfe8',
      300: '#f9a8d4',
      400: '#f472b6',
      500: '#ED107D',
      600: '#db2777',
      700: '#be185d',
      800: '#9d174d',
      900: '#831843',
      950: '#500723',
    },
    secondary: {
      50: '#e5e5e5',
      100: '#bfbfbf',
      200: '#949494',
      300: '#696969',
      400: '#494949',
      500: '#292929',
      600: '#242424',
      700: '#1f1f1f',
      800: '#191919',
      900: '#0f0f0f',
    },
    success: {
      50: 'rgb(243 250 247 )',
      100: 'rgb(222 247 236 )',
      200: 'rgb(188 240 218 )',
      300: 'rgb(132 225 188 )',
      400: 'rgb(49 196 141 )',
      500: 'rgb(14 159 110 )',
      600: 'rgb(5 122 85 )',
      700: 'rgb(4 108 78 )',
      800: 'rgb(3 84 63 )',
      900: 'rgb(1 71 55 )',
    },
  },
});
